import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import cover from "../../assets/img/hotellerie/cover.jpg"
import reception from "../../assets/img/hotellerie/reception.jpg"
import chambres from "../../assets/img/hotellerie/chambres.jpg"
import salleDeBain from "../../assets/img/hotellerie/salle-de-bain.jpg"
import barRestaurant from "../../assets/img/hotellerie/bar-restaurant.jpg"
import catalogue from "../../assets/img/hotellerie/catalogue.jpg"
import catalogueFile from "../../assets/pdf/catalogue-hotellerie.pdf"

const HotelleriePage = ({ data }) => {
    
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '2px',
    arrows: false,
  };
    const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
    return(
  <Layout>
    <SEO title="Façade" />
    <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
   <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
     <h1 class="text-center text-2xl font-bold uppercase">HÔTELLERIE</h1>
     <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <img src={cover} alt="cover" />
    </div>
   <div class="divider w-1/2 mx-auto"></div>
   <div class="grid grid-cols-1 flex items-center md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div>
            <p class="font-bold mb-2">Réception</p>
            <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
            <img src={reception} alt="réception" /></div>
        <div>
            <p class="mb-3 text-justify"> Les architectes qui conçoivent des hôtels rivalisent d’ingéniosité pour marier tradition et technologie, en alliant le charme du passé aux exigences de la vie moderne et en déployant un éventail de services – restaurants, bars, salles de remise en forme, piscine, boutiques, salon de coiffure – destinés à combler les moindres désirs du client. Le visiteur choisit aujourd’hui l’hôtel capable de lui offrir le confort sous toutes ses formes. Matériau massif, Solide Surface est composé de minéraux naturels et présente des qualités esthétiques et fonctionnelles particulièrement appréciées dans le milieu hôtelier. il séduit par sa propreté et sa fonctionnalité, son style et sa souplesse conceptuelle, Solide Surface prend aussi une dimension supplémentaire quand il est associé à d’autres matériaux pour donner une âme à un établissement hôtelier et y créer une atmosphère unique. Solide Surface crée un environnement subtil et personnalisé où élégance et confort sont omniprésents
            </p>
            <p>
                le visiteur choisit aujourd’hui l’hôtel capable de lui offrir le confort sous toutes ses formes. Matériau massif, solid surface est composé de minéraux naturels et présente des qualités esthétiques et fonctionnelles particulièrement appréciées dans le milieu hôtelier</p>

        </div>
   </div>
   <div class="divider w-1/2 mx-auto"></div>
   <div class="grid grid-cols-1 flex items-center md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div><p class="font-bold mb-2">Chambres</p>
        <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
            <p class="mb-3 text-justify">Aménagée avec goût et agrémentée de détails pertinents, la chambre s’érige en havre de sérénité pour le voyageur.</p>

           <p> Modulant la modernité de l’espace par son caractère contemporain et confortable, Solide Surface marque de son empreinte le cadre du séjour de votre client.</p>
            <p>
            Ici, une table de nuit profile ses contours nets et épurés; là, une étagère design épouse avec goût les contours d’un angle serré; là encore, la tablette du bureau accueille l’ordinateur portable comme le plateau du service d’étage.
            </p>
        </div>
        <div>
            <img src={chambres} alt="chambres" />
        </div>
   </div> 
   <div class="divider w-1/2 mx-auto"></div>
   <div class="grid grid-cols-1 flex items-center md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div>
            <p class="font-bold mb-2">Salle De Bain</p>
            <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
            <img src={salleDeBain} alt="salle De Bain" /></div>
        <div class="text-justify">
            <p class="mb-2"> Quant à la salle de bains, la douceur et la chaleur de Solide Surface en font une oasis de bien-être où il fait bon se ressourcer.</p>
            <p class="mb-2">
                Décliné en couleurs douces et apaisantes, ou au contraire choisi dans une nuance contrastée et tonique, l’habillage de baignoire en Solide Surface, coordonné au plan de toilette et au porte-savon, contribue à évacuer les tensions physiques de la journée.</p>
            <p class="mb-2">
                Sur le plan fonctionnel, Solide Surface garantit une hygiène irréprochable. Ses éléments massifs et non poreux assemblent sans joints perceptibles, créant ainsi des lavabos intégrés et des plans vasques d’une pièce, simples et faciles à nettoyer et à entretenir.</p>

        </div>
   </div>  
   <div class="divider w-1/2 mx-auto"></div>
   <div class="grid grid-cols-1 flex items-center md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div class="text-justify md:col-span-2">
            <p class="font-bold mb-2">Bar et Restaurant</p>
            <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
            <p class="mb-3">Idéal pour un déjeuner rapide et léger, le buffet accueille les convives à son comptoir en Solide Surface. Bacs à couverts intégrés, espace de réfrigération : le matériau se plie à toutes les fonctionnalités sans jamais se départir de sa fraîcheur.</p>

           <p class="mb-3">Le restaurant gastronomique où les tables sont revêtues de Solide Surface satisfait les adeptes les plus exigeants de l’esthétique et de l’hygiène combinées.</p>
            <p class="mb-3">
            En arrière-fond, la lumière tamisée du bar invite à la détente. Dans une ambiance chaleureuse et feutrée, le comptoir en Solide Surface joue l’audace des couleurs à l’image des cocktails qu’y prépare le barman. clavardages, confidences ou derniers détails d’une négociation, le monde se fait et se refait aux détours de ce comptoir ou autour des tables basses en Solide Surface.
            </p>
            <p>Et rien – ni verre renversé, ni cigarette oubliée – ne réussira à entamer sa beauté et son élégance.</p>
        </div>
        <div>
            <img src={barRestaurant} alt="bar et restaurant" />
        </div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:grid-cols-3 md:mx-40 md:my-5 mx-3 gl gl50">
       <div class="col-1 md:col-span-2"><Gallery images={g} /></div>
        <div>
            <a href={catalogueFile} class="relative block hover-trigger" download>
            <img src={catalogue} alt="cataloge cuisine" class="my-0" /> 
            <div class="absolute left-0 right-0 text-center bottom-5 bg-white border border-grey-100 px-4 py-2 hover-target">
                Télécharger
            </div>
            </a>
        </div>
    </div>
   </Layout> 
)
}
export const query = graphql`
query HotellerieGallery {
  gallery:allFile (filter:{relativeDirectory: {eq: "img/hotellerie/g"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  sld:allFile (filter:{relativeDirectory: {eq: "img/hotellerie/s"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default HotelleriePage